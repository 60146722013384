import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import SoundReactor from "./SoundReactor";
import MyGUI from "../utils/MyGUI"
import LoadingController from "./LoadingController"

class SpherePillardsClass {
  constructor() {
    this.bind();
    this.modelLoader = new GLTFLoader(LoadingController);
    this.texLoader = new THREE.TextureLoader(LoadingController);
    // create some constants for easier fine-tuning
    this.params = {
      waveSpeed: 2,
      subDiv: 3,
      pillardSize: 0.2
    }
  }

  init(scene) {
    this.scene = scene;
    // create an origin point/vector/direction in the scene, for the pillards to point "FROM"
    this.upVec = new THREE.Vector3(0, 1, 0);
    this.pillards = new THREE.Group();
    this.pillard;

    const gTex = this.texLoader.load("./assets/textures/greyMetal.png");
    const bTex = this.texLoader.load("./assets/textures/blackMetal.png");

    this.gMatCap = new THREE.MeshMatcapMaterial({
      matcap: gTex,
    });
    this.bMatCap = new THREE.MeshMatcapMaterial({
      matcap: bTex,
    });

    this.modelLoader.load("./assets/models/pillard.glb", (glb) => {
      glb.scene.traverse((child) => {
        if (child.name == "base") {
          this.pillard = child;
          child.material = this.bMatCap;
        }
        if (child.name == "Cylinder") child.material = this.gMatCap;
      });
      this.computePositions();
    });
    const sphereFolder = MyGUI.addFolder('Sphere Pillards')
    sphereFolder.open()
    sphereFolder.add(this.params, 'waveSpeed', 0.001, 5 ).name('Pillard Speed')
    // subDiv and pillardSize params are not in the rendering loop so must specify to re-run the function on change
    // use step to specifgy slider to accept integers rather than break from floats
    sphereFolder.add(this.params, 'subDiv', 1, 5 ).step(1).name('Ico Subdivisions').onChange(this.computePositions)
    sphereFolder.add(this.params, 'pillardSize', 0.01,1).name('Pillard Size').onChange(this.computePositions)
  }

  

  computePositions() {

    // must remove the previous icosphere each time before re-rendering
    let ico
    this.scene.traverse( child => {
      if(child.name == 'ico') {
        ico = child    
      }
    })
    if (ico) 
      this.scene.remove(ico)

    // IcosahedronGeometry(size, divisions)
    const sphereGeom = new THREE.IcosahedronGeometry(2, this.params.subDiv);
    const sphereMat = this.gMatCap;
    const sphere = new THREE.Mesh(sphereGeom, sphereMat);
    sphere.name = 'ico'
    // const sphere = new THREE.Mesh(sphereGeom, new THREE.MeshNormalMaterial({
    //     wireframe: true
    // }))
    this.scene.add(sphere)

    // must clear all the pillards before each re-render from the icosphere param in the GUI slider
    this.pillards.clear()

    // console.log("sphere vertices: attributes -> position -> array", sphereGeom)
    //get the x,y and z values of each vertex in the geometry and save it in a new array 'verArray'
    let verArray = [];
    for (let i = 0; i < sphereGeom.attributes.position.array.length; i += 3) {
      const x = sphereGeom.attributes.position.array[i];
      const y = sphereGeom.attributes.position.array[i + 1];
      const z = sphereGeom.attributes.position.array[i + 2];
      verArray.push({
        x: x,
        y: y,
        z: z,
      });
    }

    // due to triangulation, filter out 'duplicate' vertices from the verArray
    // "pillard position" -> will get filled up with values of the non-duplicate vertices
    let pillPos = [];
    for (let i = 0; i < verArray.length; i++) {
      let existingFlag = false;
      for (let j = 0; j < pillPos.length; j++) {
        if (
          pillPos[j].x == verArray[i].x &&
          pillPos[j].y == verArray[i].y &&
          pillPos[j].z == verArray[i].z
        ) {
          existingFlag = true;
        }
      }
      if (existingFlag == false) {
        pillPos.push({
          x: verArray[i].x,
          y: verArray[i].y,
          z: verArray[i].z,
        });
        // add a clone pillard for each vertex
        const c = this.pillard.clone();
        c.scale.multiplyScalar(this.params.pillardSize);
        // create a point/vector/direction in the scene, for the pillards to point "TO"
        // (will be the same as the existing vertex they are positioned at)
        const posVec = new THREE.Vector3(
          verArray[i].x,
          verArray[i].y,
          verArray[i].z
        );
        // refactor the 'positioning' code to use the copy method
        c.position.copy(posVec);
        // Set the rotation of each pillard to match the angle between the two points
        // The "normalize" just scales the vector.length() to make the length of the vector equal to 1
        c.quaternion.setFromUnitVectors(this.upVec, posVec.normalize());
        this.pillards.add(c);
      }
    }
    this.scene.add(this.pillards);
  }

  update() {
    if (SoundReactor.playFlag) {
        // console.log(SoundReactor.fdata)
        let i = 0;
      while (i < this.pillards.children.length) {    
          // [i] = the frequency of the pillard (is a value between 0 and 255)
          // divide by 255 to make it a value between 0 and 1
          // give it an intensity, eg. times in by 2     
        this.pillards.children[i].children[0].position.y = SoundReactor.fdata[i] / 255 * 3;
        i++;
      }
    } else {
      let i = 0;
      while (i < this.pillards.children.length) {
        // target the cylinder (child within the base shape) and
        // write a sine function (goes between -1 and 1) to animate cylinders to move between position 0 and 1
        // (the results between 0 and 2, multiplied by 0.5, give values between 0 and 1)
        // change the value of the date multiplier to edit the perceived "speed" of the animation
        this.pillards.children[i].children[0].position.y =
          (Math.sin(Date.now() * 0.01 * this.params.waveSpeed + this.pillards.children[i].position.x) +
            1) *
          1.5;
        i++;
      }
    }
  }

  bind() {
    this.computePositions = this.computePositions.bind(this)
  }
}

const _instance = new SpherePillardsClass();
export default _instance;
